<template>
  <div class="pa-4">
    <h1 class="d-flex justify-space-between align-center">
      {{ $t("menuCommandsSettings") }}
      <v-btn v-on:click="newCommandDialog = true" color="primary">
        {{ $t("btnNewCommand") }}
      </v-btn>
    </h1>

    <v-list>
      <div v-for="(command, index) in commandsList" v-bind:key="command.id">
        <CommandItem
          v-bind:command="command"
          v-bind:entity-codes="entityCodes"
          v-bind:index="index"
          v-bind:displayOwner="currentUser.isSuperAdmin"
          v-on:update="manageUpdate"
        />
        <v-divider class="my-2" />
      </div>
    </v-list>

    <v-dialog
      v-model="newCommandDialog"
      width="min(45%, 92vw)"
      persistent
      scrollable
    >
      <v-card v-if="newCommandDialog">
        <v-card-title class="d-flex align-center justify-space-between">
          {{ editCommand ? $t("editCommand") : $t("btnNewCommand") }}
          <v-switch
            v-model="newCommandEnabled"
            v-bind:label="
              newCommandEnabled ? $t('commandEnabled') : $t('commandDisabled')
            "
            inset
          />
        </v-card-title>
        <v-card-text>
          <v-alert v-if="existing" border="left" type="error" outlined text>
            {{ $t("existingCommand") }}
          </v-alert>
          <div class="d-flex align-center" style="gap: 1ch">
            <v-text-field
              v-model="newCommandNameGroup"
              v-bind:label="$t('commandNameGroup')"
              v-bind:rules="[(v) => !!v || $t('required')]"
              v-bind:outlined="outlinedPref"
              clearable
            />
            <span>/</span>
            <v-text-field
              v-model="newCommandNameField"
              v-bind:label="$t('commandNameField')"
              v-bind:rules="[(v) => !!v || $t('required')]"
              v-bind:outlined="outlinedPref"
              clearable
            />
          </div>
          <v-text-field
            v-model="newCommandDescription"
            v-bind:label="$t('commandDescription')"
            v-bind:rules="[(v) => !!v || $t('required')]"
            v-bind:outlined="outlinedPref"
            clearable
          />

          <div class="d-flex align-center">
            <v-divider class="flex-grow-1" />
            <span class="mx-4 caption">
              {{ $t("commandOptionalProps") }}
            </span>
            <v-divider class="flex-grow-1" />
          </div>

          <div class="d-flex align-center" style="gap: 1ch">
            <v-select
              v-model="newCommandEcs"
              v-bind:items="filteredEntityCodes"
              v-bind:label="$t('customerEntityCodes')"
              v-bind:outlined="outlinedPref"
              item-value="id"
              style="width: 50%"
              clearable
              multiple
            >
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content>
                    <div class="d-flex flex-column">
                      <v-text-field
                        v-model="addedEntityCode"
                        v-bind:label="$t('addUnknownEc')"
                        clearable
                      >
                        <template v-slot:append>
                          <v-btn
                            v-bind:disabled="addedEntityCode == undefined"
                            v-on:click.stop="
                              newCommandEcs.push(addedEntityCode);
                              addedEntityCode = undefined;
                            "
                            color="primary"
                            icon
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>

                      <v-list v-if="unknownEntityCodes.length > 0" dense>
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t("unknownEcs") }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-for="unknownEC in unknownEntityCodes"
                          v-bind:key="'unknown-' + unknownEC"
                          dense
                          link
                        >
                          <v-list-item-content>
                            {{ unknownEC }}
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              v-on:click.stop="
                                newCommandEcs = newCommandEcs.filter(
                                  (ec) => ec != unknownEC
                                )
                              "
                              color="error"
                              icon
                              small
                            >
                              <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>

                      <v-divider class="mb-4" />

                      <v-select
                        v-model="ecFilter"
                        v-bind:items="allTypes"
                        v-bind:label="$t('assetType')"
                        clearable
                      />
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item, index }">
                <ItemSelection
                  v-bind:index="index"
                  v-bind:item="item"
                  v-bind:array-length="newCommandEcs.length"
                  v-bind:shown-items="1"
                  mode="entityCode"
                />
              </template>
              <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item
                  v-on="on"
                  v-bind="attrs"
                  #default="{ active }"
                  class="my-0"
                >
                  <AssetItem
                    v-bind:active="active"
                    v-bind:item="item"
                    mode="entityCode"
                  />
                </v-list-item>
              </template>
            </v-select>

            <span class="mx-3">|</span>

            <v-select
              v-model="newCommandAssets"
              v-bind:items="filteredAssetsIds"
              v-bind:label="$t('customerAssets')"
              v-bind:outlined="outlinedPref"
              item-value="id"
              style="width: 50%"
              clearable
              multiple
            >
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-select
                      v-model="aIdFilter"
                      v-bind:items="allTypes"
                      v-bind:label="$t('assetType')"
                      clearable
                    />
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item, index }">
                <ItemSelection
                  v-bind:index="index"
                  v-bind:item="item"
                  v-bind:array-length="newCommandAssets.length"
                  v-bind:shown-items="1"
                  mode="asset"
                />
              </template>
              <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item
                  v-on="on"
                  v-bind="attrs"
                  #default="{ active }"
                  class="my-0"
                >
                  <AssetItem
                    v-bind:active="active"
                    v-bind:item="item"
                    mode="asset"
                  />
                </v-list-item>
              </template>
            </v-select>
          </div>

          <div class="d-flex align-center" style="gap: 1ch">
            <template v-if="currentUser.isSuperAdmin">
              <v-select
                v-model="newCommandCompany"
                v-bind:items="customersList"
                v-bind:label="$t('commandOwner')"
                v-bind:outlined="outlinedPref"
                style="max-width: 30ch"
                item-value="id"
                clearable
              >
                <template v-slot:selection="{ item }">
                  {{ item.name }}
                </template>

                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs" class="my-0">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>

              <span class="mx-3">|</span>
            </template>

            <v-select
              v-model="newCommandUsers"
              v-bind:items="usersList"
              v-bind:label="$t('menuUsers')"
              v-bind:outlined="outlinedPref"
              item-value="id"
              clearable
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <v-chip>
                  <span v-if="index < 4">
                    {{ item.nameSurname }}
                  </span>
                  <span v-else>
                    {{ $t("nMore").replace("%n%", newCommandUsers.length - 4) }}
                  </span>
                </v-chip>
              </template>
              <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item
                  v-on="on"
                  v-bind="attrs"
                  #default="{ active }"
                  class="my-0"
                >
                  <v-list-item-icon v-if="active != undefined" class="my-0">
                    <v-checkbox v-bind:input-value="active" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.nameSurname }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </div>

          <div class="d-flex align-center">
            <v-divider class="flex-grow-1" />
            <span class="mx-4 caption">
              {{ $t("commandSendArgs") }}
            </span>
            <v-divider class="flex-grow-1" />
          </div>

          <div
            v-if="newCommandArguments.length > 0"
            class="d-flex justify-center"
          >
            <v-switch
              v-model="newCommandWrapArgs"
              v-bind:label="$t('wrapCommandArgs')"
              v-bind:disabled="newCommandArguments.length > 1"
              color="primary"
              inset
            />
          </div>
          <div v-else class="d-flex justify-center mt-3 error--text">
            {{ $t("commandArgsRequired") }}
          </div>

          <v-list>
            <v-list-item
              v-for="(argument, index) in newCommandArguments"
              v-bind:key="'cmd-arg-' + index"
              style="border: 1px solid #42424242; border-radius: 4px"
            >
              <v-list-item-avatar> {{ index + 1 }}. </v-list-item-avatar>
              <v-list-item-content>
                <div class="d-flex flex-column" style="gap: 0.3em">
                  <div class="d-flex" style="gap: 2em">
                    <v-text-field
                      v-model="argument.description"
                      v-bind:label="$t('cmdArgDescription')"
                      v-bind:outlined="outlinedPref"
                      hide-details
                      clearable
                    />
                    <v-switch
                      v-model="argument.constant"
                      v-bind:label="$t('cmdArgConstant')"
                      v-bind:disabled="argument.hasEnum"
                      v-on:click="
                        argument.value = undefined;
                        argument.type = argTypes[0];
                      "
                      hide-details
                      inset
                    />
                    <v-switch
                      v-model="argument.hasEnum"
                      v-bind:label="$t('cmdArgHasEnum')"
                      v-bind:disabled="argument.constant"
                      v-on:click="
                        argument.enum = undefined;
                        argument.type = argTypes[0];
                      "
                      hide-details
                      inset
                    />
                  </div>
                  <div class="d-flex" style="gap: 2em">
                    <v-text-field
                      v-model="argument.name"
                      v-bind:label="$t('cmdArgName')"
                      v-bind:rules="[(v) => !!v || $t('required')]"
                      v-bind:outlined="outlinedPref"
                      clearable
                    />

                    <div class="d-flex" style="gap: 1em">
                      <v-text-field
                        v-if="argument.constant"
                        v-model="argument.value"
                        v-bind:label="$t('value')"
                        v-bind:rules="[(v) => !!v || $t('required')]"
                        v-bind:outlined="outlinedPref"
                        v-on:change="changedConstEnum = !changedConstEnum"
                        style="max-width: 21ch"
                        clearable
                      />
                      <v-text-field
                        v-if="argument.hasEnum"
                        v-model="argument.enum"
                        v-bind:label="$t('cmdArgEnum')"
                        v-bind:rules="[(v) => ruleArgValue(v, argument.type)]"
                        v-bind:outlined="outlinedPref"
                        v-bind:hint="$t('commandEnumHint')"
                        v-on:change="changedConstEnum = !changedConstEnum"
                        style="max-width: 21ch"
                        clearable
                      />
                      <v-select
                        v-model="argument.type"
                        v-bind:items="
                          argTypes.filter(
                            (t) =>
                              (!argument.constant || !t.includes('ARRAY')) &&
                              // eslint-disable-next-line prettier/prettier
                              (!argument.hasEnum || (!t.includes('ARRAY') && t != 'BOOL'))
                          )
                        "
                        v-bind:label="$t('commandTypeSend')"
                        v-bind:outlined="outlinedPref"
                        style="max-width: 15ch"
                        hide-details
                      />
                    </div>
                  </div>
                </div>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  v-on:click="deleteArgAt(index)"
                  color="error"
                  elevation="0"
                  fab
                  icon
                  small
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-btn
                v-on:click="addArgument()"
                color="primary"
                block
                outlined
                small
              >
                <v-icon class="me-2" small>mdi-plus</v-icon>
                {{ $t("btnAdd") }}
              </v-btn>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="closeCommandDialog" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="!validNewCommand"
            v-on:click="createCommand"
            color="primary"
            text
          >
            {{ editCommand ? $t("btnSave") : $t("btnCreate") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import ApiRequests from "../utils/requests";
import Messages from "../utils/messages";
import Pages from "../utils/pages";
import EntityCode from "../models/EntityCode";
import CommandItem from "../components/CommandItem";
import AssetItem from "../components/AssetItem";
import ItemSelection from "../components/ItemSelection";
import CommandArgument from "../models/CommandArgument";

export default {
  name: "CommandsSettings",

  components: { AssetItem, CommandItem, ItemSelection },

  data: () => ({
    entityCodes: [],
    newCommandDialog: false,
    editCommand: false,
    editCommandIndex: -1,
    ecFilter: undefined,
    aIdFilter: undefined,
    addedEntityCode: undefined,
    newCommandNameGroup: undefined,
    newCommandNameField: undefined,
    newCommandDescription: undefined,
    newCommandEnabled: false,
    newCommandCompany: undefined,
    newCommandEcs: [],
    newCommandAssets: [],
    newCommandUsers: [],
    newCommandArguments: [],
    newCommandWrapArgs: false,
    changedConstEnum: false,
    argTypes: CommandArgument.TYPES,
  }),

  computed: {
    commandsList() {
      return this.$store.getters.editableCommands;
    },
    existing() {
      return (
        !this.editCommand &&
        this.commandsList.find(
          (command) =>
            command.name ==
            `${this.newCommandNameGroup}/${this.newCommandNameField}`
        ) != undefined
      );
    },
    validNewCommand() {
      return (
        (this.changedConstEnum || !this.changedConstEnum) &&
        !!this.newCommandNameGroup &&
        !!this.newCommandNameField &&
        !!this.newCommandDescription &&
        !this.existing &&
        this.newCommandArguments.length > 0 &&
        this.newCommandArguments.reduce(
          (a, c) =>
            a &&
            !!c.name &&
            // eslint-disable-next-line prettier/prettier
            (!c.constant || (c.value !== undefined && c.value !== null && c.value !== "")) &&
            // eslint-disable-next-line prettier/prettier
            (!c.hasEnum || (c.enum !== undefined && c.enum !== null && c.enum !== "")),
          true
        )
      );
    },
    filteredEntityCodes() {
      return this.entityCodes.filter((ec) =>
        this.ecFilter ? ec.type === this.ecFilter : true
      );
    },
    unknownEntityCodes() {
      return this.newCommandEcs.filter(
        (ec) => this.entityCodes.find((k) => k.id === ec) == undefined
      );
    },
    pairedAssetsList() {
      return this.assetsList.filter((v) => !!v.boxMacAddress);
    },
    filteredAssetsIds() {
      return this.pairedAssetsList.filter((a) =>
        this.aIdFilter ? a.type === this.aIdFilter : true
      );
    },
    allTypes() {
      return this.entityCodes.map((ec) => ec.type).sort();
    },
  },

  async beforeMount() {
    this.updateFiltersRules(this.clientId);

    this.$store.commit("setCurrentPage", Pages.COMMANDS_SETTINGS_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);

    await this.getEntityCodes();
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("menuCommandsSettings"),
      to: "/commands_settings",
      root: false,
    });
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue);
    },
  },

  methods: {
    updateFiltersRules(id) {
      if (id !== undefined) {
        ApiRequests.createOrUpdateFilter(
          id,
          this.signalRBaseRegexes,
          () => {},
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
    async getEntityCodes() {
      try {
        this.entityCodes = (
          await ApiRequests.getAssetsEntityCodes(undefined, undefined)
        ).data.map((ec) => new EntityCode(ec));
      } catch {
        /* Unhandled exception */
      }
    },
    addArgument() {
      Vue.set(
        this.newCommandArguments,
        this.newCommandArguments.length,
        new CommandArgument()
      );

      if (this.newCommandArguments.length > 1) {
        this.newCommandWrapArgs = true;
      }
    },
    deleteArgAt(index) {
      Vue.delete(this.newCommandArguments, index);
    },
    resetCommand() {
      this.newCommandNameGroup = undefined;
      this.newCommandNameField = undefined;
      this.newCommandDescription = undefined;
      this.newCommandEnabled = false;
      this.newCommandCompany = undefined;
      this.newCommandEcs = [];
      this.newCommandAssets = [];
      this.newCommandUsers = [];
      this.newCommandArguments = [];
      this.newCommandWrapArgs = false;

      this.editCommand = false;
      this.editCommandIndex = -1;
    },
    manageUpdate(commandIndex) {
      const commandToEdit = this.commandsList[commandIndex];

      this.newCommandNameGroup = commandToEdit.name.split("/")[0];
      this.newCommandNameField = commandToEdit.name.split("/")[1];
      this.newCommandDescription = commandToEdit.description;
      this.newCommandEnabled = commandToEdit.enabled;
      this.newCommandCompany = commandToEdit.companyId;
      this.newCommandEcs = commandToEdit.assetEntityCodes;
      this.newCommandAssets = commandToEdit.assetIds;
      this.newCommandUsers = commandToEdit.userIds;
      // eslint-disable-next-line prettier/prettier
      this.newCommandArguments = JSON.parse(commandToEdit.arguments?.args || "[]");
      this.newCommandWrapArgs = commandToEdit.arguments?.wrap == "true";

      this.editCommand = true;
      this.editCommandIndex = commandIndex;
      this.newCommandDialog = true;
    },
    createCommand() {
      this.newCommandArguments?.forEach((arg) => {
        if (arg.constant) {
          switch (arg.type) {
            case "NUMBER":
              arg.value = Number(arg.value);
              break;
            case "BOOL":
              arg.value = `${arg.value}`.toLowerCase() == "true";
              break;
            default:
              break;
          }
        }
      });

      const newCommand = {
        name: `${this.newCommandNameGroup}/${this.newCommandNameField}`,
        description: this.newCommandDescription,
        enabled: this.newCommandEnabled,
        role: null,
        companyId: this.newCommandCompany,
        assetEntityCodes: this.newCommandEcs || [],
        assetIds: this.newCommandAssets || [],
        userIds: this.newCommandUsers || [],
        arguments: {
          wrap: `${this.newCommandWrapArgs}`,
          args: JSON.stringify(this.newCommandArguments || []),
        },
      };

      if (this.editCommand) {
        ApiRequests.updateCommand(
          this.commandsList[this.editCommandIndex].id,
          newCommand,
          () => {
            this.$bus.$emit(
              Messages.SUCCESS_MSG,
              this.$t("commandUpdateSuccess")
            );
            this.closeCommandDialog();
            this.fetchCommands();
          },
          (err) => {
            if (process.env.NODE_ENV === "development") console.error(err);
            this.$bus.$emit(Messages.ERROR_MSG, {
              error: err?.response?.data?.error || err,
              description: this.$t("commandUpdateFailure"),
            });
          }
        );
      } else {
        ApiRequests.createCommand(
          newCommand,
          () => {
            this.$bus.$emit(
              Messages.SUCCESS_MSG,
              this.$t("commandCreateSuccess")
            );
            this.closeCommandDialog();
            this.fetchCommands();
          },
          (err) => {
            if (process.env.NODE_ENV === "development") console.error(err);
            this.$bus.$emit(Messages.ERROR_MSG, {
              error: err?.response?.data?.error || err,
              description: this.$t("commandCreateFailure"),
            });
          }
        );
      }
    },
    closeCommandDialog() {
      this.newCommandDialog = false;
      this.resetCommand();
    },
    ruleArgValue(value, type) {
      if (type == "NUMBER") {
        return (
          value
            .split(",")
            .map((v) => v.trim())
            .reduce((acc, curr) => acc && !Number.isNaN(Number(curr)), true) ||
          this.$t("notANumber")
        );
      }

      return !!value || this.$t("required");
    },
  },
};
</script>
