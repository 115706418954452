<template>
  <v-dialog v-model="show" width="min(45%, 92vw)" persistent scrollable>
    <v-card>
      <v-card-title>{{ $t("btnCommands") }}</v-card-title>
      <v-card-text>
        <v-list>
          <div v-for="command in enrichedCommands" v-bind:key="command.name">
            <v-list-item>
              <v-list-item-content>
                <div class="d-flex justify-space-between align-center">
                  <div class="d-flex flex-column">
                    <div class="font-weight-bold">
                      {{ command.name }}
                    </div>
                    <div class="caption primary--text text--lighten-2">
                      {{ command.description }}
                    </div>
                  </div>

                  <div class="font-weight-bold primary--text text--lighten-2">
                    {{ getCommandType(command) }}
                  </div>
                </div>

                <div
                  v-if="notAllConstants(command)"
                  class="d-flex flex-wrap px-1 mt-1"
                  style="gap: 1ch"
                >
                  <v-card
                    v-for="(arg, index) in commandArguments(command)"
                    v-bind:key="command.name + '-arg-' + index"
                    v-show="!arg.constant"
                    dense
                  >
                    <v-card-title
                      class="caption font-weight-bold primary--text text--lighten-2 mb-0 pb-0"
                    >
                      {{ arg.type }}
                    </v-card-title>
                    <v-card-text>
                      <v-select
                        v-if="arg.hasEnum"
                        v-model="arg.value"
                        v-bind:items="arg.enum.split(',').map((v) => v.trim())"
                        v-bind:label="arg.name"
                        v-bind:rules="[(v) => ruleArgValue(v, arg.type)]"
                        v-bind:outlined="outlinedPref"
                        clearable
                      />
                      <v-switch
                        v-else-if="arg.type == 'BOOL'"
                        v-model="arg.value"
                        color="primary"
                        v-bind:label="arg.name"
                        inset
                      />
                      <OCombobox
                        v-else-if="arg.type.includes('ARRAY')"
                        v-model="arg.value"
                        v-bind:label="arg.name"
                        v-bind:rules="[(v) => ruleArgValue(v, arg.type)]"
                        v-bind:outlined="outlinedPref"
                        v-bind:hint="$t('commandArrayHint')"
                      />
                      <v-text-field
                        v-else
                        v-model="arg.value"
                        v-bind:label="arg.name"
                        v-bind:rules="[(v) => ruleArgValue(v, arg.type)]"
                        v-bind:outlined="outlinedPref"
                        clearable
                      />
                      <div class="caption">
                        {{ arg.description }}
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  v-on:click="sendCommand(command)"
                  color="primary"
                  fab
                  small
                >
                  <v-icon>mdi-send-variant-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider />
          </div>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn v-on:click="closeDialog" color="error" text>
          {{ $t("btnClose") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BoxCommand from "../models/BoxCommand";
import ApiRequests from "../utils/requests";
import Messages from "../utils/messages";
import OCombobox from "./OCombobox";

export default {
  name: "AssetCommandsDialog",

  components: { OCombobox },

  props: {
    assetId: { type: String, required: true },
    commands: { type: Array, required: true },
  },

  data: () => ({
    show: true,
    typesList: Object.keys(BoxCommand.types),
  }),

  computed: {
    enrichedCommands() {
      return this.commands.map((cmd) => {
        const boxCommand = new BoxCommand();

        boxCommand.name = cmd.name;
        boxCommand.description = cmd.description;
        boxCommand.assetIds = [this.assetId];
        boxCommand.arguments = {
          wrap: cmd.arguments?.wrap == "true",
          args: JSON.parse(cmd.arguments?.args || "[]"),
        };

        boxCommand.type = this.getCommandType(boxCommand);

        return boxCommand;
      });
    },
  },

  methods: {
    getCommandType(cmd) {
      if (cmd.arguments.args.length === 0) return "";

      if (cmd.arguments.wrap) return "JSON";

      switch (cmd.arguments.args[0].type) {
        case "NUMBER":
          return "NUMERIC";
        case "BOOL":
          return "BOOL";
        case "STRING ARRAY":
          return "JSON";
        case "NUMBER ARRAY":
          return "JSON";
        default:
          return "TEXT";
      }
    },
    notAllConstants(cmd) {
      return cmd.arguments.args.reduce((a, c) => a || !c.constant, false);
    },
    commandArguments(cmd) {
      return cmd.arguments.args;
    },
    ruleArgValue(value, type) {
      if (!value || !type.includes("NUMBER")) return true;

      if (type == "NUMBER")
        return !Number.isNaN(Number(value)) || this.$t("notANumber");

      return (
        value.reduce(
          (acc, curr) => acc && !Number.isNaN(Number(curr.text)),
          true
        ) || this.$t("notANumberInArray")
      );
    },
    sendCommand(cmd) {
      ApiRequests.sendBoxCommand(
        cmd.getBoxCommandBody(),
        () => {
          this.$bus.$emit(
            Messages.SUCCESS_MSG,
            this.$t("commandSendSuccess").replace("%c%", cmd.name)
          );
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description: this.$t("commandSendFailure").replace("%c%", cmd.name),
          });
        }
      );
    },
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>
